/**
 * common.js
 */

/* ----------------------------------------------------------
 init
---------------------------------------------------------- */
$(function () {
  telLink();
  pageScroll();
  gnaviSP();
  pageTop();
});

/* ----------------------------------------------------------
  check screen size
---------------------------------------------------------- */
var mq = window.matchMedia('screen and (min-width: 769px)');
var isPC = mq.matches;

mq.addListener(function () {
  isPC = mq.matches;
  $(window).trigger('breakpointChange');
});

/* ----------------------------------------------------------
  lock screen
---------------------------------------------------------- */
var lockScreen = function () {
  var $html = $('html');
  var top   = $(window).scrollTop();

  if(!$html.hasClass('is-fixed')) {
    $html
      .addClass('is-fixed')
      .css({ top: (top * -1) })
      .data('top', top);
  }
};

/* ----------------------------------------------------------
  unlock screen
---------------------------------------------------------- */
var unlockScreen = function () {
  var $html = $('html');
  var top   = $html.data('top') || 0;

  if($html.hasClass('is-fixed')) {
    $html
      .removeClass('is-fixed')
      .css({ top: '' });
    $(window).scrollTop(top);
  }
};

/* ----------------------------------------------------------
  pageScroll
---------------------------------------------------------- */
var pageScroll = function () {
  $('.js-scroll').on('click', function () {
    var speed = 400;
    var href= $(this).attr("href") || '';
    var minus = $('.header').height() || 0;
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top - minus;

    if(href == '#'){
      $('body,html').animate({scrollTop:0}, speed, 'swing');
    } else {
      $('body,html').animate({scrollTop:position}, speed, 'swing');
    }

    return false;
  });
};

/* ----------------------------------------------------------
  telLink
---------------------------------------------------------- */
var telLink = function () {
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0) {
    $('.js-tel-link').each(function () {
      var html = $(this).html();
      var cls  = $(this).attr('class');
      $(this).replaceWith( '<span class="' + cls + '">' + html + '</span>');
    });
  }
};

/* ----------------------------------------------------------
 gnaviSP
---------------------------------------------------------- */
var gnaviSP = function(){
  var $btnMenu = $('.header-btn-menu');
  var $btnAccordion = $('.nav-global-link');
  var $navSP = $('.nav-global');
  var bg = $('.nav-global-bg');

  $btnMenu.click(function(){
    if(!($btnMenu.hasClass('is-open'))) {
      $navSP.addClass('is-open');
      $btnMenu.addClass('is-open');
      bg.addClass('show');
      fitMenuHeight();
      lockScreen();
    } else {
      closeGnavi();
      unlockScreen();
    }
  });

  bg.click(function(){
    closeGnavi();
  });

  function fitMenuHeight () {
		$navSP.height("auto");
		var menu_h = $navSP.height();
    var header_h = $(".header").height();
		var win_h = $(window).height() - header_h;

    if(win_h > menu_h){
      $navSP.height("auto");
      $navSP.css({ overflowY: "auto" });
    }else {
      $navSP.height(win_h);
      $navSP.css({ overflowY: "scroll" });
    }
	}

  function closeGnavi(){
    $navSP.removeClass('is-open').removeAttr("style");
    bg.removeClass('show');
    $btnMenu.removeClass('is-open');
    $('.nav-global-menu').removeClass('showsub');
    $('.nav-global-sub-menu').removeClass('showsub').stop().slideUp();
    unlockScreen();
  }

  /* gnavi accordion */
  $btnAccordion.click(function(){
    if($(this).closest('.nav-global-list-item').find('.nav-global-sub-menu').length > 0) {

      if($(this).parent().hasClass('showsub')) {
        $(this).parent().removeClass('showsub');
        $(this).closest('.nav-global-list-item').find('.nav-global-sub-menu').stop().slideUp(function(){
          fitMenuHeight();
        });
      } else {
        $(this).parent().addClass('showsub');
        $(this).closest('.nav-global-list-item').find('.nav-global-sub-menu').stop().slideDown(function(){
          fitMenuHeight();
        });
      }
    }
  });


  var $oldMQ = isPC;
  var curWidth, oldWidth;

  if ($oldMQ == false) {
    $oldMQ = 'SP';
  } else {
    $oldMQ = 'PC';
  }

  $(window).resize(function() {
    if (isPC) {
      $MQ = 'PC';
    } else {
      $MQ = 'SP';
    }

    if($MQ != $oldMQ ) {
      if( $MQ == 'PC') {
        $oldMQ = 'PC';
        closeGnavi();
      } else {
        $oldMQ = 'SP';
      }
    }

    if( $MQ == 'SP') {
      if($navSP.hasClass('is-open')){
        var curWidth = $(this).outerWidth();
        if(curWidth != oldWidth) {
          oldWidth = curWidth;
          fitMenuHeight();
        }
      }
    }
  });
};

/* ----------------------------------------------------------
 Page Top
---------------------------------------------------------- */
var pageTop = function(){
  var top = $('html').offset().top;
	var $pageTop = $('.c-btn-pagetop');
  var $footer  = $(".footer");
  if($('.slider-main').length){
    if($(window).width() >= 1024) {
      var top = $('.slider-main').height()-700;
    }
  }

  $(window).scroll(function() {
    var winHeight = $(this).height();
    var ftOffset = $footer.offset().top;
    var stopPos  = ftOffset - (winHeight - 20);

    if ($(this).scrollTop()> top) {
      $pageTop.fadeIn();
    } else {
      $pageTop.fadeOut();
    }

    if ($(this).scrollTop() >= stopPos) {
      $pageTop.addClass("stop");
    } else {
      $pageTop.removeClass("stop");
    }
  });
};

/* ----------------------------------------------------------
  global-navigation
---------------------------------------------------------- */

//pc用
$('.nav-local-list-item').hover(function() {
  $(this).children('.nav-child-wrap').hide();
  $(this).children('.nav-child-wrap').slideDown();
}, function() {
  $(this).children('.nav-child-wrap').hide();
});

//スマホ用
$(function(){
  //スマホ用クラス
  var className = 's-nav-local-list';
  var smallClass = '.' + className;
  //PC用をクローン
  $( '#lg-menu' ).clone().removeClass().addClass( className ).attr( 'id', '' ).prependTo( '#sm-menu' );
  //DOM整形
  $( smallClass + ' .nav-child-category').unwrap();
  $( smallClass + ' .nav-child-list').unwrap().unwrap();
  $( smallClass + ' .en, ' + smallClass + ' br, ' + smallClass + ' i').remove();
  //ul+ulを結合
  $( smallClass + ' .nav-child-wrap ul + ul').each(function( index, element ) {
    $( element ).appendTo( $( element ).prev() ).children().unwrap();
  })
  //.nav-child-categoryをリスト内にコピー
  $( 'a.nav-child-category' ).each(function( index, element ) {
    $( element ).clone().prependTo($( element ).next()).wrap( '<li></li>' );
  });
  //クラス除去
  $( smallClass + ' *').removeClass();
  //クラス付与
  $( smallClass + ' > li').addClass( 's-nav-parent nav-first' );
  $( smallClass + ' > li > div' ).addClass( 's-nav-parent s-nav-child nav-second' );
  $( smallClass + ' > li > div > ul' ).addClass( 's-nav-child nav-third' );
  $('.s-nav-child').prev().addClass('trigger');
  //クリックイベント
  $('.trigger').on('click', function(e){
    //aタグ無効
    e.preventDefault();
    //やじるし処理
    $(this).toggleClass('current');
    $(smallClass).find('.current').not($(this)).not(
      //$(this).parent().prev('.trigger')
      $(this).parents('.nav-first').find('.current').not($(this).parent().find('.current'))
    ).removeClass('current');
    //子メニュー表示
    $(this).next('.s-nav-child').slideToggle();
    $(smallClass).find('.s-nav-child').not($(this).next('.s-nav-child')).not(
      $(this).parents('.nav-first').find('.s-nav-child').not($(this).parent().find('.s-nav-child'))
    ).slideUp();
    //息子以下を閉じる
    $(this).next().find('.current').removeClass('current');
    $(this).next().find('.s-nav-child').hide();
  });
  //メニュー自体を閉じたら初期化
  $('.header-btn-menu').on('click', function(){
    $('.s-nav-child').hide();
    $('.trigger').removeClass('current');
  });
});//function

/* ----------------------------------------------------------
  アンカーで飛ばした時のズレ解消用
---------------------------------------------------------- */
$(function() {
    var headerHight = $("#nav_local").height() + $("header").height();
    jQuery('a[href^="#"]').click(function() {
      var speed = 800;
      var href = jQuery(this).attr("href");
      var target = jQuery(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top - headerHight;
      jQuery("body,html").animate({ scrollTop: position }, speed, "swing");
      return false;
    });

    // 診療科
    let vars = [], max = 0, hash = '', array = '';
    const url = window.location.search;
    // ? を取り除くため、1から始める。複数のクエリ文字列に対応するため、&で区切る
    hash = url.slice(1).split('&');
    max  = hash.length;
    for ( let i = 0; i < max; i++ ) {
      array = hash[i].split('='); // keyと値に分割。
      vars.push(array[0]); // 末尾にクエリ文字列のkeyを挿入。
      vars[array[0]] = array[1]; // 先ほど確保したkeyに、値を代入。
    }
    if( vars.anchor ){
      const anc = document.getElementById(vars.anchor)
      const rect = anc.getBoundingClientRect()
      setTimeout(window.scrollTo( '0', rect.top - headerHight + 32 ), 100)
    }


});
